export function localizeState(state, online = false, defaultValue = '') {
  return stateNameAndValues.get(`${state}${online ? 'Online' : ''}`) ?? stateNameAndValues.get(`${state}`) ?? defaultValue
}

export const stateNameAndValues = new Map(
  [
    ['Registered', 'Biztos jön'],
    ['Canceled', 'Lemondta'],
    ['Appeared', 'Megjelent'],
    ['Waiting', 'Várólistán'],
    ['NotAppeared', 'Nem jelent meg'],
    ['WontAttend', 'Biztos nem jön'],
    ['Interested', 'Érdeklődött'],
    ['Applied', 'Jelentkezett'],
    ['Denied', 'Elutasított'],
    ['NotResponded', 'Vissza nem jelzett'],

    ['RegisteredOnline', 'Biztos jön - online'],
    ['AppearedOnline', 'Megjelent - online'],
    ['NotAppearedOnline', 'Nem jelent meg - online'],
  ]
)
