import React, { useCallback, useEffect, useState } from "react"
import { Consts } from "../Consts"


export function LinkOpens() {
  const eventid = localStorage.getItem('event')
  const [opens, setOpens] = useState(null)

  const fetchMessages = useCallback(async () => {
    let url = `${Consts.baseUrl}api/link-opens?filters[event][id]=${eventid}&populate=user`
    // if (!!eventid && eventFilter) url += `&eventid=${eventid}`
    const respone = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    })
    return await respone.json()
  }, [eventid])

  const queryOpensToUi = useCallback(async () => {
    const messages = await fetchMessages(false)
    setOpens(messages.data)
  }, [fetchMessages])

  useEffect(() => {
    queryOpensToUi()
  }, [queryOpensToUi])

  if (!opens)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    )

  // async function exportMessagesToXls() {
  //   setLoading(true)
  //   const xls = await fetchMessages(true)
  //   const anchor = document.createElement('a')
  //   anchor.href = `${Consts.baseUrl}${xls.path}`
  //   anchor.download = xls.path.split('/').slice(-1)
  //   document.body.appendChild(anchor);
  //   anchor.click();
  //   document.body.removeChild(anchor);
  //   setLoading(false)
  // }

    console.log('opens', opens)

  return (
    <div className="col">
      <div className="eventbottomwrapper">
        <h2>KATTINTHATÓ LINK MEGNYITÁSOK</h2>
        {/* <div className="end-aligned-text">
          {
            !!eventid && (
              <>
                <input
                  id='eventFilter'
                  type="checkbox"
                  checked={eventFilter}
                  onChange={e => setEventFilter(e.target.checked)} />
                <label
                  htmlFor="eventFilter"
                  style={{ marginLeft: 8, marginRight: 16 }}>eseményre szűrés</label>
              </>
            )
          }
          <select
            value={selectedLimit}
            style={{ marginRight: 16 }}
            onChange={e => setSelectedLimit(e.target.value)}
          >{
              LIMITS.map((limit, index) => {
                const isLast = index === LIMITS.length - 1
                return (
                  <option key={index} value={limit}>{isLast ? 'összes' : `${limit} db`}</option>
                )
              })
            }
          </select>
          <input
            className={'button ' + (loading && 'button-disabled')}
            type="button"
            value={loading ? 'Exportálás...' : 'Exportálás'}
            onClick={exportMessagesToXls}
            disabled={loading}
          />
        </div> */}
        {opens.length === 0 && <div>Nincs még link megnyitás.</div>}
        {opens.length > 0 &&
          <table className="onsite mtop25 table table-bordered table-striped">
            <thead>
              <tr>
                <th scope="col">Név</th>
                <th scope="col">Utolsó megnyitás</th>
              </tr>
            </thead>
            <tbody>
              {
                opens.map((o, index, array) => {

                  console.log('asd', array.filter(i => i.attributes.user?.data.id === o?.attributes.user?.data?.id).map(i => new Date(i.attributes.openedAt)))

                  return array.indexOf(array.find(i => i.attributes.user?.data?.id === o?.attributes.user?.data?.id)) === index ? ({
                  id: o?.attributes.user?.data?.id,
                  name: (o?.attributes.user?.data?.attributes?.lastName ?? '') + ' ' + (o?.attributes?.user?.data?.attributes?.firstName ?? ''),
                  count: array.filter(i => i.attributes.user?.data.id === o?.attributes.user?.data?.id).length,
                  openedAt: new Date(Math.max(...array.filter(i => i.attributes.user?.data.id === o?.attributes.user?.data?.id).map(i => new Date(i.attributes.openedAt))))
                }) : null}).filter(o => o).map((id, index) => {
                  console.log('id', id)
                  return (
                    <tr key={index}>
                      <td>{id?.name} <strong>x {id?.count}</strong></td>
                      <td>{id.openedAt.toLocaleString()}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>}
      </div>
    </div>
  )
}
