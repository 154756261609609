import { Consts } from "../Consts"

export class EventsModel {

	constructor(model) {
		this.id = model.id
		this.title = model.title
    this.description = model.description
		const start = new Date(model.start_date)
		this.startDate = {
			year: start.getFullYear(),
			month: Consts.MONTHS[start.getMonth()],
			day: start.getDate(),
			dayName: Consts.DAYS[start.getDay()]
		}
		this.startTime = makeStarttime(start)
		const endTime = model.end_time.split(':')
		this.endTime = endTime[0] + ":" + endTime[1]
    this.isHero = model.is_hero
		this.pictureSmall = Consts.baseUrl + model.picture?.formats?.small?.url
		this.pictureMedium = Consts.baseUrl + model.picture?.formats?.medium?.url
		this.lecturerNames =  model.lecturers?.map((lecturer) => lecturer.name)
		this.club = model.club?.id
    this.category = model.category?.id
		this.theme = model.theme
		this.addresses = model.addresses
    this.lunch = model.lunch
    this.code = model.code ?? 'nincs?'
	}
}

function makeStarttime(start) {
  return (
    (start.getHours() < 10 ? "0" : "") +
    start.getHours().toString() +
    ":" +
    (start.getMinutes() < 10 ? "0" : "") +
    start.getMinutes().toString()
  );
}
