import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { Consts } from "../Consts";
import noProfilePhotoIcon from "../assets/no-profile-photo.png";

export function NotResponds() {
  const { event } = useOutletContext();
  const [users, setUsers] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    await fetch(
      Consts.baseUrl +
        "api/event-registrations/notresponds/" +
        localStorage.getItem("event"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then(async (res) => setUsers(await res.json()));
  }
  async function insertOrUpdateRegistration(userId, newValue) {
    await fetch(`${Consts.baseUrl}api/event-registrations/adminRegister/${localStorage.getItem("event")}/${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: newValue,
    }).then(res => res.json()).then(() => {
      const reg = users.find(r => r.id === userId)
      if(reg){
        const index = users.indexOf(reg)
        if(index !== -1) users.splice(index, 1)
      }
      setUsers([...users])
    })
  }

  if (!users || !event)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    );

  console.log(users)

  return (
    <div className="row wrapper">
      <div className="col-4">
        <h2>Vissza nem jelzettek:</h2>
      </div>

      <table className="event-registrations table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Státusz</th>
            <th scope="col">Fotó</th>
            <th scope="col">Név</th>
            <th scope="col">Cégnév</th>
            <th scope="col">Email</th>
            <th scope="col">Mobilszám</th>
            <th scope="col">Bevételi kategória</th>
            <th scope="col">Ütközés ID</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((r, index) => {
              return (
                <tr key={index}>
                  <th>
                    <Dropdown onSelect={(eventKey) => insertOrUpdateRegistration(r.id, eventKey)}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Hozzáadás
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Registered", online: false})}>Biztos jön</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Canceled"})}>Lemondta</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Appeared", online: false})}>Megjelent</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Waiting"})}>Várólista</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Interested"})}>Érdeklődő</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Applied"})}>Jelentkezett</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Denied"})}>Elutasított</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "WontAttend"})}>Biztos nem jön</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "NotAppeared", online: false})}>Nem jelent meg</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Registered", online: true})}>Biztos jön - online</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Appeared", online: true})}>Megjelent - online</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "NotAppeared", online: true})}>Nem jelent meg - online</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                  <th className="imgwrapper" scope="row">
                    <img
                      src={
                        (r && r.picture && r.picture.url)
                          ? Consts.baseUrl + r.picture.url.slice(1)
                          : noProfilePhotoIcon
                      }
                      className="img-fluid rounded"
                      alt="..."
                    />
                  </th>
                  <td>
                    {r.lastName} {r.firstName}
                  </td>
                  <td>{r.company?.name ?? "Nincs cég megadva"}</td>
                  <td>{r.email}</td>
                  <td>{r.phoneNumber}</td>
                  <td>{r.incomeCategory?.name}</td>
                  <td style={{ textAlign: "center" }} >{r.company?.reservationConflictId}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
