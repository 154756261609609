import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { Consts } from "../Consts";
import noProfilePhotoIcon from "../assets/no-profile-photo.png";

import { Dropdown } from "react-bootstrap";
import { localizeState } from "../utils/registrationRenderHelpers";

export function Applies() {
  const { event } = useOutletContext();
  const [regs, setRegs] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    await fetch(
      Consts.baseUrl +
        "api/event-registrations/findbyevent/" +
        localStorage.getItem("event"),
      {
        method: "POST",
        body: JSON.stringify({
          state: ['Applied']
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then(async (res) => setRegs(await res.json()));
  }
  async function insertOrUpdateRegistration(userId, newValue) {
    await fetch(`${Consts.baseUrl}api/event-registrations/adminRegister/${localStorage.getItem("event")}/${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: newValue,
    }).then(res => res.json()).then(res => {
      if(res.registration.state !== 'Applied'){
        const reg = regs.find(r => r.user.id === userId)
        if(reg){
          const index = regs.indexOf(reg)
          if(index !== -1) regs.splice(index, 1)
        }
      }
      else {
        const reg = regs.find(r => r.user.id === userId)
        if(reg){
          reg.state = res.registration.state
        }
        else regs.push(res.registration)
      }
      setRegs([...regs])
    })
  }

  if (!regs || !event)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    );

  return (
    <div className="row wrapper">
      <div className="col-4">
        <h2>Jelentkezések:</h2>
      </div>

      <table className="event-registrations table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Státusz</th>
            <th scope="col">Fotó</th>
            <th scope="col">Név</th>
            <th scope="col">Cégnév</th>
            <th scope="col">Ütközés ID</th>
          </tr>
        </thead>
        <tbody>
          {regs &&
            regs.sort((a, b) => b.state.localeCompare(a.state)).map((r, index) => {
              return (
                <tr key={index}>
                  <th scope="row">
                    <Dropdown onSelect={(eventKey) => insertOrUpdateRegistration(r.user.id, eventKey)}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {localizeState(r.state, event.addresses.some(a => !a.location || a.location === "") && r.online)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Registered", online: false})}>Biztos jön</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Canceled"})}>Lemondta</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Appeared", online: false})}>Megjelent</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Waiting"})}>Várólista</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Interested"})}>Érdeklődő</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Applied"})}>Jelentkezett</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Denied"})}>Elutasított</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "WontAttend"})}>Biztos nem jön</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "NotAppeared", online: false})}>Nem jelent meg</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Registered", online: true})}>Biztos jön - online</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Appeared", online: true})}>Megjelent - online</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "NotAppeared", online: true})}>Nem jelent meg - online</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                  <th className="imgwrapper" scope="row">
                    <img
                      src={
                        (r.user && r.user?.picture && r.user?.picture?.url)
                          ? Consts.baseUrl + r.user.picture.url.slice(1)
                          : noProfilePhotoIcon
                      }
                      className="img-fluid rounded"
                      alt="..."
                    />
                  </th>
                  <td>
                    {r.user.lastName} {r.user.firstName}
                  </td>
                  <td>{r.user.company?.name ?? "Nincs cég megadva"}</td>
                  <td style={{ textAlign: "center" }} >{r.user?.company?.reservationConflictId}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
