import { faCopy, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QRCodeCanvas } from "qrcode.react";
import React, { Fragment, useRef } from "react";
import { useOutletContext } from "react-router";
import { Consts } from "../Consts";

export function EventDetail() {
  const {event} = useOutletContext()
  const qrRef = useRef();

  if(!event)
    return (
      <div className="spinner-border" role="status" style={{"--bs-spinner-width": "5rem", "--bs-spinner-height": "5rem", "--bs-spinner-border-width": "0.5em"}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )

  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const sendSurvey = async () => {
    console.log("sending survey")
    await fetch(Consts.baseUrl + "api/surveys/send/"+event.survey.id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then(async res => console.log("Success:", await res.json()))
  };
  const downloadSurveyResult = async () => {
    const res = await fetch(Consts.baseUrl + "api/survey-answers/"+event.survey.id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    }).then(res => res.json())
    let anchor = document.createElement("a");
    anchor.href = Consts.baseUrl+res.filePath;
    anchor.download = res.filePath.split('/').slice(-1);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const appurl = `${Consts.baseSiteUrl}app?e=${event.id}`;

  return (
    <div className="col">
      <div className="eventtopwrapper">
        <div className="event_image_wrapper">
        <h2 style={{alignSelf: 'flex-start'}}>ESEMÉNY ADATLAPJA:</h2>
        <img src={event.pictureMedium} className="img-fluid rounded-start" alt="..." />
        </div>
      <div className="row qrcode_wrapper" style={{marginBottom: 10}} ref={qrRef}>
        <QRCodeCanvas
          id="qrCode"
          value={event.code}
          size={200}
        />
        <input className="qrcode_button" type="button" value="QRCode letöltése" onClick={downloadQRCode} />
      </div>
      </div>
      <div className="eventbottomwrapper">
      <table className="table table-bordered table-striped table-hover ">
        <tbody>
      <tr>
        <td>
        Code:
        </td>
        <td>
        {event.code ?? 'nincs?'}
        </td>
      </tr>
      <tr>
        <td>
        Cím:
        </td>
        <td>
        {event.title}
      </td>
      </tr>
      <tr>
        <td>
        Időpont:
        </td>
        <td>
        {event.startDate?.year}. {event.startDate?.month} {event.startDate?.day}. ({event.startDate?.dayName}), {event.startTime} - {event.endTime}
        </td>
      </tr>
      <tr>
        <td>
        Leírás:
        </td>
        <td>
        {event.description}
        </td>
      </tr>
      <tr>
        <td>
        Előadók:
        </td>
        <td>
        {event.lecturerNames?.join(', ')}
        </td>
      </tr>
      <tr>
        <td>
        Helyszín:
        </td>
        <td>
        {event.addresses?.filter(a => a.location && a.location !== '')?.map(a => a.location)?.join(', ')}
        </td>
      </tr>
      <tr>
        <td>
        Ebéd:
        </td>
        <td>
        {event.lunch ? 'Van' : 'Nincs'}
        </td>
      </tr>
      <tr>
        <td>
        Kattintható link:
        </td>
        <td>
        <div style={{cursor: 'pointer'}} onClick={(e) => {
          e.preventDefault();
          var textField = document.createElement('textarea')
          textField.innerText = appurl
          document.body.appendChild(textField)
          textField.select()
          document.execCommand('copy')
          textField.remove()
        }}>{appurl} <FontAwesomeIcon icon={faCopy} /></div>
        </td>
      </tr>
      <tr>
        <td>
        Kérdőív:
        </td>
        <td>
          { event?.survey?.title ?
            <Fragment>
              <div className="event_title">{event?.survey?.title}</div>
              <input id="send" className="button" type="button" title="Kérdőív kiküldése a bejelentkezett felhasználóknak." onClick={() => sendSurvey()} />
              <input id="download" className="button" type="button" title="Kérdőív válaszainak letöltése" onClick={() => downloadSurveyResult()} />
            </Fragment> : null
          }
        </td>
      </tr>
      <tr>
        <td>
        Link megnyitások száma:
        </td>
        <td>
          { event?.linkOpens?.all ?? 0 }
        </td>
      </tr>
      <tr>
        <td>
        Egyedi link megnyitások száma:
        </td>
        <td>

          <a href="link-opens" style={{color: 'black', textDecoration: 'none', display: 'block'}}>
            { event?.linkOpens?.unique ?? 0 } <FontAwesomeIcon icon={faUpRightFromSquare} />
          </a>
        </td>
      </tr>
      </tbody>
      </table>
    </div>
    </div>
  )
}
