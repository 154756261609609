import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { Consts } from "../Consts";
import noProfilePhotoIcon from "../assets/no-profile-photo.png";
import SynchronizationButton from "../components/SynchronizationButton";
import { localizeState } from "../utils/registrationRenderHelpers";

export function NewRegistrations() {
  const { event } = useOutletContext();

  const [res, setRes] = useState([]);
  useEffect(() => console.log("USER RESULT CHANGED:", res), [res])

  const [regs, setRegs] = useState(null);
  useEffect(() => console.log("REGISTRATIONS CHANGED:", regs), [regs])

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetch(
      Consts.baseUrl +
        "api/event-registrations/findbyevent/" +
        localStorage.getItem("event"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    ).then(async (res) => setRegs(await res.json()));
  }, []);

  if (!event || !regs)
    return (
      <div
        className="spinner-border"
        role="status"
        style={{
          "--bs-spinner-width": "5rem",
          "--bs-spinner-height": "5rem",
          "--bs-spinner-border-width": "0.5em",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    );

  const search = () => {
    fetch(Consts.baseUrl + "api/users/?s=" + searchText, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then(async (res) => {
      console.log(res)
      setRes(
        (await res.json()).map(u => {return {
          id: u.id,
          picture: u.picture,
          name: `${u.lastName} ${u.firstName}`,
          companyName: u.company?.name,
          reservationConflictId: u.company?.reservationConflictId,
        }}).sort((a, b) => a?.name.localeCompare(b?.name))
      )
    });
  };

  function insertOrUpdateRegistration(userId, newValue) {
    fetch(`${Consts.baseUrl}api/event-registrations/adminRegister/${localStorage.getItem("event")}/${userId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: newValue,
    }).then(res => res.json()).then(res => {
      console.log(res)
      const reg = regs.find(r => r.user.id === userId)
      if(reg) reg.state = res.registration.state
      else regs.push(res.registration)
      setRegs([...regs])
    })
  }
  const syncWithCRM = () => {
    fetch(Consts.baseUrl + "api/crm/sync-users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(() => {
        // console.log("RES: ", JSON.stringify(res));
      });
  }

  return (
    <div className="row wrapper">
      <div className="new-registration-upper-div">
        <h2>ÚJ RÉSZTVEVŐ HOZZÁADÁSA:</h2>
        <div className="new-registration-upper-div-upper-row">
          <div className="new-registration-upper-div-lower-row">
            <input
              className="inputSearch"
              type="search"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <input
              className="searchbutton"
              type="button"
              value="Keresés"
              onClick={search}
            />
          </div>
          <SynchronizationButton onSync={syncWithCRM}/>
        </div>
      </div>

      {res && (
        <table className="new-registration table table-bordered">
          <thead>
            <tr key="header">
              <th scope="col">Státusz</th>
              <th scope="col">Fotó</th>
              <th scope="col">Név</th>
              <th scope="col">Cégnév</th>
              <th scope="col">Ütközés ID</th>
            </tr>
          </thead>
          <tbody>
            {res?.map((u, index) => {
              const state = regs.find(r => r.user.id === u.id)?.state ?? null
              const isOnline = regs.find(r => r.user.id === u.id)?.online ?? null
              return (
                <tr key={index}>
                  <td>
                    <Dropdown onSelect={(eventKey) => insertOrUpdateRegistration(u.id, eventKey)}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {localizeState(state, event.addresses.some(a => !a.location || a.location === "") && isOnline, 'Hozzáadás')}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Registered", online: false})}>Biztos jön</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Canceled"})}>Lemondta</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Appeared", online: false})}>Megjelent</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Waiting"})}>Várólista</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Interested"})}>Érdeklődő</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Applied"})}>Jelentkezett</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Denied"})}>Elutasított</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "WontAttend"})}>Biztos nem jön</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "NotAppeared", online: false})}>Nem jelent meg</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Registered", online: true})}>Biztos jön - online</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "Appeared", online: true})}>Megjelent - online</Dropdown.Item>
                        <Dropdown.Item eventKey={JSON.stringify({state: "NotAppeared", online: true})}>Nem jelent meg - online</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className="imgwrapper" scope="row">
                    <img
                      src={
                        (u.picture && u.picture?.url)
                          ? Consts.baseUrl + u.picture.url.slice(1)
                          : noProfilePhotoIcon
                      }
                      className="img-fluid rounded"
                      alt="..."
                    />
                  </td>
                  <td>
                    {u.name}
                  </td>
                  <td>{u.companyName ?? "Nincs cég megadva"}</td>
                  <td style={{ textAlign: "center" }} >{u.reservationConflictId}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
